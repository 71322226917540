import React from 'react'
import { graphql } from 'gatsby'
import { filterPostNodesFromEdges } from '../lib/helpers'
import Container from '../components/Container'
import GraphQLErrorList from '../components/GraphQLErrorList'
import AllPosts from '../components/AllPosts'
import SEO from '../components/SEO'
import LayoutContainer from '../containers/LayoutContainer'

export const query = graphql`
  query AllPostsTemplateQuery($isKorean: Boolean!) {
    posts: allSanityPost(
      filter: {
        isKorean: { eq: $isKorean }
        hiddenFromList: { ne: true }
        series: { _type: { eq: null } }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          series {
            _key
            _type
            series {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const postNodes = filterPostNodesFromEdges((data || {}).posts)
  const isKorean = props.pageContext.isKorean

  return (
    <LayoutContainer>
      {errors && <SEO title="GraphQL Error" />}
      {postNodes && <SEO title="All Posts" lang={isKorean ? 'ko' : 'en'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {postNodes && <AllPosts isKorean={isKorean} postNodes={postNodes} />}
    </LayoutContainer>
  )
}

export default BlogPostTemplate
