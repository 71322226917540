/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from './Container'
import BlogPostPreviewList from './BlogPostPreviewList'

function getSearchUrl () {
  if (typeof window !== 'undefined') {
    return `/search?from=${encodeURIComponent(window.location.pathname)}`
  } else {
    return '/search'
  }
}

export default ({ isKorean, postNodes }) => {
  const commonStyle = {
    mr: 2,
    px: 1,
    pb: '2px',
    color: 'blue',
  }
  const englishStyle = {
    ...commonStyle,
    fontSize: 'base',
    ...(!isKorean
      ? { borderBottom: 'lightBlue' }
      : { textDecoration: 'none', opacity: 0.6, '&:hover': { opacity: 1 } }),
  }
  const koreanStyle = {
    ...commonStyle,
    fontSize: 'sm',
    ...(isKorean
      ? { opacity: 0.8, borderBottom: 'lightBlue' }
      : {
        opacity: 0.5,
        textDecoration: 'none',
        '&:hover': { opacity: 1 },
      }),
  }
  return (
    <Container>
      <Link
        to='/'
        sx={{
          color: 'black',
          opacity: 0.6,
          '&:hover': {
            opacity: 0.8,
          },
        }}
      >
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z'
          />
        </svg>
        <span hidden>Go Back Home</span>
      </Link>
      <div
        sx={{
          mt: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {[
          {
            title: 'English',
            selected: !isKorean,
            href: '/posts',
            sx: englishStyle,
          },
          {
            title: '한국어',
            selected: isKorean,
            href: '/posts/ko',
            sx: koreanStyle,
          },
        ].map(({ title, selected, href, sx }) =>
          selected ? (
            <span key={title} sx={sx}>
              {title}
            </span>
          ) : (
            <Link key={title} to={href} sx={sx}>
              {title}
            </Link>
          )
        )}
        <Link to={getSearchUrl()} sx={{ lineHeight: 'none' }}>
          <svg
            sx={{
              width: 6,
              height: 6,
              color: 'black',
              opacity: 0.6,
              '&:hover': { opacity: 0.8 },
            }}
            viewBox='0 0 24 24'
          >
            <path
              fill='currentColor'
              d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z'
            />
          </svg>
        </Link>
      </div>
      <BlogPostPreviewList nodes={postNodes} />
    </Container>
  )
}
